.error-fallback {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 2vw;
    padding-top: 10vh;
  }
  
  .error-fallback h1 {
    font-size: 5vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
  }
  
  .error-fallback button {
    width: 20%;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5vw;
    font-weight: 300;
    line-height: 1.5vw;
    padding: 1vw 2vw;
    background-color: #0b0a09;
    color: white;
    border: none;
    border-radius: 100px;
    cursor: pointer;
  }
  
  .error-fallback button:hover {
    background-color: #333;
  }
  