.portfolio-page-section-mobile {
  padding-top: 16vw;
  padding-bottom: 5vw;
  background-color: #f6f4f4;
  position: relative;
}

.button-up-mobile {
  position: fixed;
  width: 14vw;
  bottom: 50%;
  right: 2.5vw;
  cursor: pointer;
  z-index: 100;
}

.button-up-hide-mobile {
  display: none;
}

.portfolio-page-switcher-container-mobile {
  padding: 4vw 0;
}

.portfolio-page-switcher-container-mobile select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 2vw 0;
  width: 90vw;
  font-size: 5vw;
  border-bottom: 1px solid #000;
  cursor: pointer;
  background-image: url('%PUBLIC_URL%/img/chevron_Down.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 4.5vw 4vw;
  font-family: 'Inter';
  font-weight: 300;
  color: #0b0a09;
}

.portfolio-page-switcher-container-mobile select:focus {
  border-bottom: 1px solid #000;
}

.portfolio-page-container-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
}

.portfolio-page-background-mobile {
  width: 200%;
  height: auto;
  display: block;
  filter: brightness(0.6);
}

.portfolio-page-overlay-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portfolio-page-text-overlay-mobile {
  position: absolute;
  top: 60%;
  left: 48%;
  width: 80vw;
  transform: translate(-50%, -50%);
  color: #f6f4f4;
  text-align: left;
  z-index: 1;
}

.portfolio-page-heading-mobile {
  font-family: 'Ubuntu', sans-serif;

  font-size: 6vw;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}

.portfolio-page-text-mobile {
  font-family: 'Inter', sans-serif;
  font-size: 4vw;
  font-weight: 300;
  line-height: 4vw;
  letter-spacing: 0em;
  text-align: left;
}

.portfolio-page-switcher-container-mobile {
  display: flex;
  justify-content: center;
  margin-top: 5vw;
}

.portfolio-page-image-renderComponent-mobile {
  width: 90vw;
  height: 100vw;
}

.portfolio-page-grid-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4vw;
}

.portfolio-page-item-mobile {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 10vw;
}

.portfolio-page-residentialComplex-mobile {
  font-family: 'Ubuntu', sans-serif;
  font-size: 5vw;
  font-weight: 400;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.portfolio-page-address-mobile {
  font-family: 'Inter', sans-serif;
  font-size: 4vw;
  font-weight: 400;
  line-height: 3vw;
  letter-spacing: 0em;
  text-align: left;
  width: 80vw;
  color: black;
}

.portfolio-page-sizeSquareMeters-mobile {
  font-family: 'Inter', sans-serif;
  font-size: 3.5vw;
  font-weight: 300;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.portfolio-page-item-mobile {
  overflow: hidden;
  cursor: pointer;
}
